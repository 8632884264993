import React from 'react';
import './CoverImage.css';

const CoverImage = ({priceInsights,qty,totalTrade}) => {
    return (
        <div className="cover-image-container">
            <div className="image-overlay"></div>
            <img src="https://amsaf-marketplace.s3.af-south-1.amazonaws.com/Production/agri_dashboard.png" alt="Cover" className="cover-image" loading='lazy'/>
            <div className="cover-text-container">
                <div className="cover-text">
                    Delivering credibility and reliability<br />
                    to African  agriculture markets.
                </div>
                <div className='data-div'>
                    <div className='text-[#DAFF0F]'>
                        {qty ? <p>{qty} MT</p> : <p>0</p>}
                        <p className='yellow-bold-line' style={qty ? { width: `${String(qty).length * 10}%` } : {width:'20%'}}></p>
                        <p className='text-[1rem] pt-2'>Quantity Sold</p>
                    </div>
                    <div className='text-[#DAFF0F]'>
                        {totalTrade ? <p>{totalTrade}</p> : <p>0</p>}
                        <p className='yellow-bold-line' style={totalTrade ? { width: `${String(totalTrade).length * 20}%` } : {width:'20%'}}></p>
                        <p className='text-[1rem] pt-2'>Trade Listed</p>
                    </div>
                    <div className='text-[#DAFF0F]'>
                        {priceInsights ? <p>{priceInsights}</p> : <p>0</p>}
                        <p className='yellow-bold-line' style={priceInsights ? { width: `${String(priceInsights).length * 20}%`} : {width:'20%'}}></p>
                        <p className='text-[1rem] pt-2'>Price Insights</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoverImage;
