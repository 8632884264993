import { useState, useEffect } from 'react';
import './CommodityPrice.css';

const CommodityPrice = ({ commodityPrices }) => {


    const [isHovered, setIsHovered] = useState(false);

    const CommodityPriceView = () => {
        return (
            <div className='flex gap-8 items-center'>
                {
                    commodityPrices.map((item, index) => (
                        <div key={index} className='flex items-center gap-1 min-w-fit'>
                            <p><span>{item.commodity_name}</span>({item.state_name ? item.state_name : null}-{item.district_name ? item.district_name : null})</p>
                            <p className='flex'>{Number(item.avg_price).toLocaleString('en-US', { maximumFractionDigits: 2 })}{" "}{item.currency_unit_name}/{item.weight_unit_name}</p>
                        </div>
                    ))}
            </div>
        )
    }


    useEffect(() => {
        const scrollText = document.getElementById('scroll-text');
        if (!scrollText) return;

        const numItems = scrollText.children.length; // Get the number of items
        const scrollContainer = document.getElementById('scroll-container');
        if (!scrollContainer) return;

        const containerWidth = scrollContainer.offsetWidth; // Get the width of the container

        const firstItem = scrollText.children[0];
        const itemWidth = firstItem.offsetWidth;
        const totalWidth = itemWidth * numItems; // Total width of all items together

        // Adjust the animation duration based on the total width and container width
        const duration = (totalWidth / containerWidth) * 40; // Scale duration accordingly

        document.documentElement.style.setProperty('--scroll-duration', `${duration}s`);
    }, [commodityPrices]); // Only run once after the component mounts


    return (
        commodityPrices.length > 0 ?
            <div id="commodity-container">
                <div id="scroll-header-text">
                    <p>Market Price : </p>
                </div>
                <div id="scroll-container"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}>
                    <div id="scroll-text" style={{ animationPlayState: isHovered ? 'paused' : 'running' }}>
                        {CommodityPriceView()}
                        {CommodityPriceView()}
                        {CommodityPriceView()}
                    </div>
                </div>
            </div> : null
    )
}

export default CommodityPrice;
