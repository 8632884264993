export const ourServicesNigeria = [
    {
        title: "AMSAF Hubs",
        content: "Aggregation infrastructure network providing agriculture produce origination, quality assurance, fulfilment and export services."
    },
    {
        title: "AMSAF Marketplace",
        content: " Digital platform for trading produce with vetted counterparties."
    },
    {
        title: "AMSAF Financing Solutions",
        content: "Access financing from empanelled financial institutions to grow your business."
    },
    {
        title: "AMSAF Pre-harvest Solutions",
        content: "Field-level & satellite-based yield estimation & crop monitoring tools to ensure healthy crops."
    },
]