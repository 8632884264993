import React from "react";
import locationicon from "../../static/images/location_on_24px.svg";
import fbImg from '../../static/images/Nigeria/fb.png';
import linkedInImg from '../../static/images/Nigeria/linkedIn.png';
import twitterImg from '../../static/images/Nigeria/twitter.png';
import youtubeImg from '../../static/images/Nigeria/youtube.png';
import './FooterNigeria.css';

const Footer = () => {
    return (
        <div className="footer-nigeria-div">



            {/* <div className="flex flex-col items-start gap-2 mb-6 lg:mb-0">
                <p className="font-bold text-[1.3rem]">Quick Links</p>
                <p>Our Services</p>
                <p>Our Story</p>
                <p>Contact Us</p>
                <p>Terms of Use</p>
            </div> */}

            <div className="connect-with-us-div ">
                <p className="font-bold text-[1.3rem] connect-header">CONNECT WITH US</p>
                <div className="flex gap-4">
                    <div>
                        <p className="underline connect-number">+234 803 6693 098</p>
                        <p className="underline connect-number">+234 706 7172 067</p>
                    </div>

                    <div className="border-l-2 border-white-400 border-solid">
                        <p className="flex items-start pl-2"><img src={locationicon} alt="." />15B Ojumo, Ikoyi<br />
                            Lagos, Nigeria<br />
                        </p>
                    </div>
                </div>
                {/* <div className="flex gap-3">
                    <img src={fbImg} alt="fb" className="h-6 w-6" />
                    <img src={linkedInImg} alt="linkedIn" className="h-6 w-6" />
                    <img src={twitterImg} alt="twitter" className="h-6 w-6" />
                    <img src={youtubeImg} alt="youtube" className="h-6 w-6" />
                </div> */}
            </div>

            <div className="footer-bottom-div">
                <div className="footer-img-div">
                    <img src="https://amsaf-marketplace.s3.af-south-1.amazonaws.com/Production/agri_logo.svg" alt="AMSAF-LOGO" className="" loading='lazy'/>

                </div>

                <div className="footer-download-div">
                    <p className="font-bold text-[1.3rem]">DOWNLOAD AMSAF APP</p>
                    <img src="https://amsaf-marketplace.s3.af-south-1.amazonaws.com/Production/playstore.svg" loading='lazy' alt="playstore" className="h-[4rem] w-[12rem] md:h-[3rem] md:w-[10rem] cursor-pointer" onClick={() => {
                        let url = "https://play.google.com/store/apps/details?id=com.amsaf_mobile"
                        window.open(url, '_blank')
                    }} />
                </div>
            </div>

        </div>
    )
}

export default Footer;
