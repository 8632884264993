import React, { useState } from "react";
import { ourServicesNigeria } from "./ourServicesData";
import './OurServices.css';

const OurServices = () => {
    const [isOpen, setIsOpen] = useState([false, false, false, false]);

    function handleOpenDropdown(inputIndex) {
        let newState = [...isOpen];
        newState = newState.map((item, index) => index === inputIndex ? !item : false);
        setIsOpen(newState);
    }

    return (
        <div className="our-services">
            <div className="our-services-div">
                {/* Services Section */}
                <div className="our-services-data-div">
                    {ourServicesNigeria.map((item, index) => (
                        <div
                            className="our-services-content-div"
                            key={index}
                        >
                            <div className="our-services-content-div-header" onClick={() => handleOpenDropdown(index)}>
                                <h1>
                                    {item.title}
                                </h1>
                                {
                                    isOpen[index] ?
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                                            <path d="M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z" />
                                        </svg>
                                        :

                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                                            <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
                                        </svg>
                                }
                            </div>

                            <div
                                className={`our-services-content-text-div ${isOpen[index] ? "our-services-content-text-div open" : "our-services-content-text-div closed"}`}
                            >
                                {isOpen[index] && (
                                    <p>
                                        {item.content}
                                    </p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Image Section */}
                <div className="our-services-image-div ">
                    <img src="https://amsaf-marketplace.s3.af-south-1.amazonaws.com/Production/services.png" alt="Our Services" loading="lazy"/>
                </div>
            </div>
        </div>
    );
};

export default OurServices;
