import React from "react";
import agriLogo from '../../static/images/Nigeria/agri_logo.svg';
import appConfig from "../../config";

const Navbar = ({ servicesRef, footerRef,aboutUsRef }) => {

    const scrollToServices = () => {
        if (servicesRef && servicesRef.current) {
            servicesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToFooter = () => {
        if (footerRef && footerRef.current) {
            footerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToAboutUs = () => {
        if (aboutUsRef && aboutUsRef.current) {
            aboutUsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className="flex items-center justify-between md:p-0 p-4 flex-wrap md:flex-nowrap">

            <div className="flex justify-center w-full md:w-auto">
                <img src="https://amsaf-marketplace.s3.af-south-1.amazonaws.com/Production/agri_logo.svg" alt="AMSAF-LOGO" className="w-auto p-2" loading='lazy'/>
            </div>

            <div className="flex items-center justify-evenly gap-8 font-medium text-[1.2rem] sm:text-[1.2rem] md:text-[1.2rem] mr-[2rem] w-full md:w-auto mt-4 md:mt-0">
                <p onClick={scrollToAboutUs} className="cursor-pointer">About Us</p>
                <p onClick={scrollToServices} className="cursor-pointer">Our Services</p>
                <p onClick={scrollToFooter} className="cursor-pointer">Contact Us</p>

                <div className="bg-[#5AC6FC] rounded-[10px] w-[10rem]" onClick={() => {
                    let url = appConfig.ourServices.marketplace[appConfig.environment]
                    window.open(url, '_blank');
                }}>
                    <p className="p-2 text-center cursor-pointer">Trade Now</p>
                </div>

            </div>

        </div>
    );
}

export default Navbar;
