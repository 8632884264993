import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import CommodityPrice from "../components_nigeria/CommodityPrice/CommodityPrice";
import Navbar from "../components_nigeria/NavbarNigeria.jsx/Navbar";
import CoverImage from "../components_nigeria/CoverImage/CoverImage";
import OurServices from "../components_nigeria/OurServices/OurServices";
import Footer from "../components_nigeria/FooterNigeria/FooterNigeria";
import AboutUsNigeria from "../components_nigeria/AboutUsNigeria/Aboutus";

import appConfig from "../config";

const HomeNigeria = () => {

    const [commodityPrices, setCommodityPrices] = useState([]);
    const [priceInsights, setPriceInsights] = useState("");

    const [qty, setQty] = useState("");
    const [totalTrade, setTotalTrade] = useState("");

    const servicesRef = useRef(null);
    const footerRef = useRef(null);
    const aboutUsRef = useRef(null);

    const fetchCommodityPrices = async () => {
        const params = {
            commodity_id: null,
            state_ids: null,
            district_ids: null,
            search: null,
            sort: "",
            sort_order: "",
            offset: 0,
            limit: 100000,
            status: 1
        };

        const headers = {
            'accept': 'application/json',
            'X-localization': 'en',
            'Org-Code': "WAF",
            'Org_Code': "WAF",
        }
        try {
            const response = await axios.get(`${appConfig.amsaf.agriculture[appConfig.environment]}/commodityPrice/getcommodityprices`, {
                params: params,
                headers: headers
            });
            setCommodityPrices(response.data.data);
            setPriceInsights(response.data.recordsTotal);
        } catch (err) {
            console.log('Error fetching commodity prices', err);
        }
    }

    const fetchMarketplaceTotals = async () => {
        const params = {
            total_order: true,
            summary: true,
        };

        const headers = {
            'accept': 'application/json',
            'X-localization': 'en',
            'Org-Code': "WAF",
            'Org_Code': "WAF",
            'client-name': `${appConfig.amsaf.private[appConfig.environment].name}`,
            'client-token':`${appConfig.amsaf.private[appConfig.environment].token}`,
        }
        try {
            const response = await axios.get(`${appConfig.amsaf.private[appConfig.environment].url}/market_places`, {
                params: params,
                headers: headers
            });
            setQty(response.data.data.total_quantity_in_MT);
            setTotalTrade(response.data.data.total_count);
        } catch (err) {
            console.log('Error fetching marketplace totals', err);
        }
    }


    useEffect(() => {
        fetchMarketplaceTotals();
    },[])

    useEffect(() => {
        fetchCommodityPrices();

        const intervalId = setInterval(() => {
            fetchCommodityPrices();
        }, 30000);

        return () => clearInterval(intervalId);

    }, []);

    return (
        <div className="bg-white font-[poppins]">
            <Navbar servicesRef={servicesRef} footerRef={footerRef} aboutUsRef={aboutUsRef}/>
            <CommodityPrice commodityPrices={commodityPrices} />
            <CoverImage priceInsights={priceInsights} qty={qty} totalTrade={totalTrade}/>

            <div ref={aboutUsRef} id="about-us">
            <AboutUsNigeria />
            </div>

            <div ref={servicesRef} id="our-services">
                <OurServices />
            </div>

            <div ref={footerRef} id="footer">
                <Footer />
            </div>
        </div>
    )
}

export default HomeNigeria;