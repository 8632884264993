import React from "react";
import './AboutUsNigeria.css';

const AboutUsNigeria = () => {
    return (
        <div className="about-us">
            <div className="about-us-container">
                <div className="border-green">
                    <div className="our-mission-div">
                        <div className="our-mission-text">
                            <h2>Our Mission</h2>
                            <p>To create a systematic and reliable Agriculture value chain that enables all players to connect and transact through an interoperable, data-driven digital ecosystem that ensures the best innovative approach to investment. To structure the market towards a sustainable & scalable last-mile delivery structure.</p>
                        </div>

                        <div className="our-mission-img">
                            <img src="https://amsaf-marketplace.s3.af-south-1.amazonaws.com/Production/OurMissionNigeria.svg" alt="Our Mission" loading='lazy'/>
                        </div>
                    </div>



                    <div className="our-vision-div">
                        <div className="our-vision-img">
                            <img src="https://amsaf-marketplace.s3.af-south-1.amazonaws.com/Production/OurMissionNigeria.svg" alt="Our Vission" loading='lazy'/>
                        </div>

                        <div className="our-vision-text">
                            <h2>Our Vision</h2>
                            <p>To become the fastest growing Agri Tech start-up and one of the very few companies providing end-to-end solutions to the farming communities, village aggregators, and industrial processors through a scalable innovative commodity-focused strategy.</p>
                        </div>
                    </div>

                </div>

                <div className="bg-[#F9FFFA] padding-bottom-css border-l border-[#D7F4D7] border-r">
                    <div className="we-are-div">
                        <div className="we-are-text">
                            <h2>Who we are</h2>
                            <p>AMSAF, formerly known as AgriGoo, is a subsidiary commodity trading platform under CropIT. It enables an organisation to accomplish its aggregation goals by strengthening, increasing the supply of raw materials, and providing visibility in the ecosystem for new growth and scale up.</p>
                        </div>
                        <div className="we-are-img-div">
                            <div className="we-are-img">
                                <img src="https://amsaf-marketplace.s3.af-south-1.amazonaws.com/Production/agri_logo.svg" alt="AgriGoo" className="w-[12rem]" loading='lazy'/>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="bg-[#fff]">
                    <div className="what-we-div">
                        <div className="what-we-text">
                            <h2>What we do</h2>
                            <p>Providing an enabling commodity trading ecosystem where all actors are efficient and reliable towards fulfilling the demand and supply trade request without destabilizing prices at the open market and retail value chains.</p>
                        </div>
                        <div className="what-we-img-div">
                            <div className="what-we-img">
                                <img src="https://amsaf-marketplace.s3.af-south-1.amazonaws.com/Production/what_we_do.svg" alt="What We Do" loading='lazy'/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default AboutUsNigeria;